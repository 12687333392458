const co2liquefactionData = [
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Maldives, Asia",
        capacityTPD: 2.5,
        compressor: "Recip with VFD",
        refrigerant: "R404a",
        controller: "PLC",
        numUnits: 1,
        imagePath: "/machines-images/CO2 Liquefaction/maldives_2.5.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Meerut, Uttar Pradesh, India",
        capacityTPD: 50,
        compressor: "Recip",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 1,
        imagePath: "/machines-images/CO2 Liquefaction/co2_liquefaction_meerut_50TPD.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Kinshasa, Congo, Africa",
        capacityTPD: 12,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 2,
        imagePath: "/machines-images/CO2 Liquefaction/kinshasa_congo.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Andhra Pradesh, India",
        capacityTPD: 100,
        compressor: "Recip 2-Stage x 2",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/telangana_100.png",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Bundki, Uttar Pradesh, India",
        capacityTPD: 40,
        compressor: "Recip 2-Stage",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/bundki_UP_40.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Myanmar, Asia",
        capacityTPD: 12,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/Myanmar_Liquefaction_12.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Indore, Madhya Pradesh, India",
        capacityTPD: 4.8,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/maksi_indore.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Harare, Zimbabwe, Africa",
        capacityTPD: 12,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/zimbabwe.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Vardhman, West Bengal, India",
        capacityTPD: 72,
        compressor: "Recip 2-Stage",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/west_bengal.jpeg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Raipur, Chattisgarh, India",
        capacityTPD: 43,
        compressor: "Recip 2-Stage",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/west_bengal_40TPD.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for N₂O Liquefaction",
        location: "Kosi, Uttar Pradesh, India",
        capacityTPD: 1.2,
        compressor: "Recip",
        refrigerant: "R404a",
        controller: "PLC",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/haryana_1_2TPD.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Kanpur, Uttar Pradesh, India",
        capacityTPD: 86,
        compressor: "Recip 2-Stage",
        refrigerant: "Ammonaia",
        controller: "PLC with Touch Screen ",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/kanpur_3600kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Bareilly, Uttar Pradesh, India",
        capacityTPD: 72,
        compressor: "Recip 2-Stage",
        refrigerant: "Ammonaia",
        controller: "PLC with Touch Screen ",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/webbareilly.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Abu Dhabi, UAE",
        capacityTPD: 50,
        compressor: "Screw, 2 no. (Tandem Design)",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen and Electronic Expansion Valve",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/abu_2000kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Balaghat, Madhya Pradesh, India",
        capacityTPD: 43,
        compressor: "Recip 2-Stage",
        refrigerant: "Ammonaia",
        controller: "PLC with Touch Screen ",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/nagpur_1800kg.jpg",
        under_production: false,
    },
    {
        location: "Karnataka, India",
        capacityTPD: 36,
        compressor: "Screw",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/karnatka_1500kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Roorkee, Uttarakhand, India",
        capacityTPD: 24,
        compressor: "Screw",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/am_Roorkee_1100kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Kenya, Africa",
        capacityTPD: 24,
        compressor: "Screw",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/kenya_1100kg.jpg",
        under_production: false,
    },
    {
        location: "Nagda, Madhya Pardesh, India",
        capacityTPD: 24,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/mp_1100kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Uganda, Africa",
        capacityTPD: 20,
        compressor: "Screw",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/uganda_850kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Karnataka, India",
        capacityTPD: 14,
        compressor: "2 Stage Recip",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/karnatka_600kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Assam, India",
        capacityTPD: 36,
        compressor: "2 Stage Recip",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/assam.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Algeria, Africa",
        capacityTPD: 12,
        compressor: "Screw",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/algeria_500kg.jpeg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Sri Lanka, Asia",
        capacityTPD: 12,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/srilanka_500kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "SAARC, Asia ",
        capacityTPD: 12,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 2,
        imagePath: "/machines-images/CO2 Liquefaction/saarc_beh_500kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Yemen, Asia",
        capacityTPD: 7.2,
        compressor: "Screw",
        refrigerant: "Ammonia",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/yemen_300kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Bhutan, Asia",
        capacityTPD: 7.2,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/Bhutan_300kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Congo, Africa",
        capacityTPD: 7.2,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/congo_300kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Orissa, India",
        capacityTPD: 4,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/orrisa_180kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Assam, India",
        capacityTPD: 3.6,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/assam_150kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Equatorial Guinea, Africa",
        capacityTPD: 2.4,
        compressor: "Recip.",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/guinea_120kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Benin, Africa",
        capacityTPD: 2.4,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 2,
        imagePath: "/machines-images/CO2 Liquefaction/benin_110kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Burkina Faso, Africa",
        capacityTPD: 2.4,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/bulkina_110kg.jpg",
        under_production: false,
    },
    {
        name: "Refrigeration Unit for CO₂ Liquefaction",
        location: "Lubumbashi, Congo, Africa",
        capacityTPD: 4.8,
        compressor: "Screw",
        refrigerant: "R404a",
        controller: "PLC with Touch Screen",
        numUnits: 0,
        imagePath: "/machines-images/CO2 Liquefaction/lubumbashi.jpg",
        under_production: false,
    },
]

export default co2liquefactionData;